.tabbar {
  border: 0;
}

@media (prefers-color-scheme: light) {
  .transparent {
    --background: white;
  }
}

@media (prefers-color-scheme: dark) {
  .transparent {
    --background: black;
  }
}

.react-pdf__Page__canvas {
  height: 100% !important;
  padding: 10px;
  width: 100% !important;
}
